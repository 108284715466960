<!-- 
	This is the sign in page, it uses the dashboard layout in: 
	"./layouts/Default.vue" .
 -->

<template>
	<div class="sign-in">
		
		<a-row type="flex" :gutter="[24,24]" justify="space-around" align="middle">

			<!-- Sign In Form Column -->
			<a-col :span="24" :md="12" :lg="{span: 12, offset: 0}" :xl="{span: 6, offset: 2}" class="col-form">
				<h1 class="mb-15">Sign In</h1>
				<h5 class="font-regular text-muted">Enter your email and password to sign in</h5>

				<!-- Sign In Form -->
				<a-form
					id="components-form-demo-normal-login"
					:form="form"
					class="login-form"
					@submit="handleSubmit"
					:hideRequiredMark="true"
				>
					<a-form-item class="mb-10" label="Email" :colon="false">
						<a-input 
						v-decorator="[
						'email',
						{ rules: [{ required: true, message: 'Please input your email!' }] },
						]"
						 placeholder="Email" />
					</a-form-item>
					<a-form-item class="mb-5" label="Password" :colon="false">
						<a-input
						v-decorator="[
						'password',
						{ rules: [{ required: true, message: 'Please input your password!' }] },
						]" type="password" placeholder="Password" />
					</a-form-item>
					<a-form-item class="mb-10">
    					<a-switch v-model="rememberMe" /> Remember Me
					</a-form-item>
					<a-form-item>
						<a-button type="primary" block html-type="submit" class="login-form-button">
							Sign in
						</a-button>
					</a-form-item>
					<a-form-item>
						OR
					</a-form-item>
					<a-form-item>
						<a-button type="icon" block @click="sloging">
							<font-awesome-icon :icon="['fab','google']"/> Sign in with Google
						</a-button>
						<a-button type="icon" block @click="sloginm">
							<font-awesome-icon :icon="['fab','microsoft']"/> Sign in with Microsoft
						</a-button>
					</a-form-item>
				</a-form>
				<!-- / Sign In Form -->
				<p class="font-semibold text-muted">Don't have an account? <router-link to="/sign-up" class="font-bold text-dark">Sign Up</router-link></p>
			</a-col>
			<!-- / Sign In Form Column -->

			<!-- Sign In Image Column -->
			<a-col :span="24" :md="12" :lg="12" :xl="12" class="col-img">
				<img src="images/lock-g434af788a_640.png" alt="">
			</a-col>
			<!-- Sign In Image Column -->

		</a-row>
		
	</div>
</template>

<script>
	import {firebase} from "../utils/firebaselib";
	import storage from "store";
	import request from "../utils/request";
	import { ACCESS_TOKEN } from '@/store/mutation-types'
	export default ({
		data() {
			return {
				// Binded model property for "Sign In Form" switch button for "Remember Me" .
				rememberMe: true,
				email:'',
				password:'',
			}
		},
		beforeCreate() {
			// Creates the form and adds to it component's "form" property.
			this.form = this.$form.createForm(this, { name: 'normal_login' });
		},
		methods: {
			getToken(){
				firebase.auth().currentUser.getIdToken(true).then((idToken)=>{
					request.post('/api/verify',{token:idToken})
						.then(response=>{
							storage.set(ACCESS_TOKEN, response.jwttoken, 7 * 24 * 60 * 60 * 1000);
							this.$store.commit('SET_NAME',{name:response.name,welcome:''});
							this.$store.commit('SET_TOKEN',response.jwttoken);
							this.$router.replace('/dashboard');

						});
				});
			},
			sloging(e){
				e.preventDefault();
				var gauth = new firebase.auth.GoogleAuthProvider();
				gauth.addScope('https://www.googleapis.com/auth/userinfo.email');
				firebase.auth().signInWithPopup(gauth).then((result)=>{
					this.getToken();
				}).catch(error=> {alert(error)});
			},
			// Handles input validation after submission.
			sloginm(e){
				e.preventDefault();

				var gauth = new firebase.auth.OAuthProvider('microsoft.com');
				firebase.auth().signInWithPopup(gauth).then((result)=>{
					this.getToken();
				}).catch((error)=>{
					alert(error);
				}
				);
			},
			handleSubmit(e) {
				e.preventDefault();
				this.form.validateFields((err, values) => {
					if ( !err ) {
						firebase.auth().signInWithEmailAndPassword(this.form.getFieldValue('email'), this.form.getFieldValue('password'))
							then((result) => {
								alert('Successfully registered! Please login.');
								this.$router.push('/dashboard');
							})
							.catch((e) => {
								alert(e);
							});
					}
				});
				return;
			},
		},
	})

</script>

<style lang="scss">
	body {
		background-color: #ffffff;
	}
</style>